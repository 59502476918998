import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip as ReactTooltip } from "react-tooltip";

function TwoRowStat(props){

    function renderCustomTooltip(){
        return (
            <div>
               
                <p className="tooltip-grey-font no-margin">
                    Incidenza:
                    <span className="tooltip-yellow-font">&nbsp;{props.incidenzaStringa && props.incidenzaStringa !== "" ? props.incidenzaStringa : "0"}</span>
                </p>
            
            </div>
        )
    }

    function setPercentuale(){
        let old_cases = props.total;
        let current_cases = props.num_casi_attuali;

        if(old_cases === 0 || current_cases === 0){
            return (
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                   
                </div>
            )
        }

        let percentuale = parseInt((parseInt(current_cases) * 100 / parseInt(old_cases)));
        
        if(percentuale)
        if(percentuale > 100){
            return (
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"12px",
                            color:"#7585b5",
                            marginLeft:"16px"
                        }}
                    >
                        {percentuale - 100} %
                    </label>
                    <ArrowDropUp
                        style={{
                            color:"#d5942c",
                            width:"24px",
                            height:"24px"
                        }}
                    />
                </div>
            )
        } else {
            return (
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"12px",
                            color:"#7585b5",
                            marginLeft:"16px"
                        }}
                    >
                        {100 - percentuale } %
                    </label>
                    <ArrowDropDown
                        style={{
                            color:"#d5942c",
                            width:"24px",
                            height:"24px"
                        }}
                    />
                </div>
            )
        }

    }

    return (
        <div
            className="stat-two-row-responsive"
            style={{
                padding:"14px",
                backgroundColor: `${props.isCurrentYear ? "#F7BE6280" : "#dde4f9"}` ,
                borderRadius: "28px",
                marginBottom: "8px"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <label
                    style={{
                        fontFamily:"SemiBold",
                        fontSize:"14px",
                        color: "#344472" ,
                    }}
                >
                    Anno {props.year}
                </label>
                
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    {/* <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"12px",
                            color:"#7585b5",
                            marginLeft:"16px"
                        }}
                    >
                        {props.incidenza} 
                    </label> */}
                    {/* <InfoIcon 
                        id={props.id}
                        
                        style={{
                            color:"#d5942c",
                            width:"18px",
                            height:"18px",
                            marginLeft:"8px"
                        }}
                    /> */}
                    <ReactTooltip
                        anchorId={props.id}
                        place="top"
                        border="2px solid #DDE4F9"
                        style={{
                            backgroundColor:"#F6F8FE",
                            borderRadius: "16px",
                            fontFamily: "Medium",
                            color: "#261D56",
                            //opacity: statRegione.counters.last_years[statRegione.counters.last_years.length-1].provincia["103"].opacity
                        }}
                        render={() => renderCustomTooltip()}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "0px"
                }}
            >
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"12px",
                        color: "#7585B5",
                        marginRight: "4px"
                    }}
                >
                    Num. casi:
                </label>
                <label
                    style={{
                        fontFamily:"SemiBold",
                        fontSize:"14px",
                        color:"#344472",
                        
                    }}
                >
                    {props.total}
                </label>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"12px",
                        color: "#7585B5",
                        marginRight: "4px"
                    }}
                >
                    Incidenza:
                </label>
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <InfoIcon 
                        id={props.id}
                        
                        style={{
                            color:"#d5942c",
                            width:"14px",
                            height:"14px",
                            marginRight:"4px"
                        }}
                    />
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"14px",
                            color:"#344472"
                            
                        }}
                    >
                        {props.incidenza}
                    </label>
                </div>
            </div>
        </div>
    )
}

export default TwoRowStat;
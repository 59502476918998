import React, {useState} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';

const SearchBar = (props) => {

const options = setMenuOptions();

function setMenuOptions(){
    const malattie = props.malattie;
    let malattieOptions = [];
    malattie.map((malattia,index) => (
        malattieOptions.push({
            value: malattia.nome,
            label: malattia.nome,
            id: malattia._id,
            info: malattia.info,
            section_asl: malattia.section_asl,
            section_province: malattia.section_province
        })
    ))
    return malattieOptions;
}

const handleChange = event => {
    if(event && event.label){
        props.handleMalattia({
            nome: event.label,
            _id: event.id,
            info: event.info,
            section_asl: event.section_asl,
            section_province: event.section_province
        });
    }
    
};

function deleteMalattia(){
    props.handleMalattia("ANTRACITE");
}


return (
    <div 
        
        style={{
            display: "flex",
            alignItems:"center",
            justifyContent:"center",
            position: "relative",
            width: "100%"
        }}
    >

        <Select 
            options={options} 
            placeholder="Cerca malattia infettiva..."
            onChange={handleChange}
            isClearable={true}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    width:"350px",
                    height: "44px",
                    padding: "0px 12px",
                    borderRadius: "24px",
                    border: "2px solid #dde4f9",
                    backgroundColor: "#f6f8fe",
                    
                    fontFamily: "Regular",
                    fontSize: "14px",
                })
            }}
            
        />
        {/* <input
            type="search"
            placeholder="Cerca malattia infettiva..."
            onChange={handleChange}
            value={searchInput} 
            style={{
                width: "80%",
                height: "44px",
                padding: "12px",
                borderRadius: "24px",
                border: "2px solid #dde4f9",
                backgroundColor: "#f6f8fe",
                color: "#344472",
                fontFamily: "Regular",
                fontSize: "14px"
            }}
        /> */}
            
        {/* <SearchIcon 
            style={{
                width: "28px",
                height: "28px",
                color: "#7585b5",
                position: "absolute",
                top:"8px",
                right: "10px"
            }}
        /> */}


    </div>


)};

export default SearchBar;
import DownloadIcon from "@material-ui/icons/GetApp"

const ButtonCSV = ({exportCSV, id, series}) => {
    function exportToCSV(){
        exportCSV(id, series);
    }

    return (
        <div>
            <button
                className="btn-csv" 
                onClick={exportToCSV}
            >
                Scarica CSV
                <DownloadIcon 
                    style={{
                        width: "28px",
                        height: "28px",
                        color: "white" 
                    }}
                />
            </button>
        </div>
    );
}

export default ButtonCSV;
import React from 'react';
import styled from 'styled-components';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { useState } from 'react';

const Ul = styled.ul`
    list-style: none;
    z-index: 20;
    padding-left: 12px;
    padding-right: 12px;
    
    li {
        padding: 12px 15px;
        color: white;
        cursor: pointer;
    }
  
    display: flex;
    flex-flow: column nowrap;
    background-color: #344472;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
    top: 60px;
    left: -220px;
    height: 100vh;
    width: 220px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease 0s;
    font-family: Regular;
    font-size: 16px;
    color: "white";
  
`;

const RightNav = ({ open, handleMalattia, closeMenu, malattie, malattiaSelected }) => {

    const [ordinamento, setOrdinamento] = useState(0);

    function scegliMalattia(malattia){
        handleMalattia(malattia);
        closeMenu();
    }

    function handleOrdinamento(){
        
        if(ordinamento === 0){
            malattie.sort((a,b) => b.total - a.total);
            setOrdinamento(1);
        } else {
            malattie.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
            setOrdinamento(0);
        }
    }

    return (
    
        <Ul open={open}>
            <div style={{
               padding: "8px 15px",
               marginBottom: "0px"
            }}
            >
                <p 
                    style={{
                        fontFamily:"Regular",
                        fontSize: "12px",
                        color: "#7585b5",
                        marginBottom: "0px"
                    }}
                >
                    Ordina per:
                </p>
                <div 
                    style={{
                        cursor:"pointer",
                        marginBottom: "12px"
                    }}

                    onClick={handleOrdinamento}
                >
                    <span 
                        style={{
                            fontFamily:"SemiBold",
                            fontSize: "14px",
                            color: "#F7BE62"
                        }}
                    >
                        {ordinamento === 0 ? "Ordine alfabetico" : "Ordine per peso malattia"}
                    </span>
                </div>
                {/* <p 
                    style={{
                        fontFamily:"Regular",
                        fontSize: "14px",
                        color: "#7585b5"
                    }}
                >
                    Seleziona la malattia per visualizzare i dati:
                </p> */}
                <ArrowUpward 
                    style={{
                        color:"white",
                        width: "12px",
                        height: "12px",
                    }}
                />
            </div>
            <div
                className="menu-height-responsive"
                style={{
                    overflowY: "scroll",
                    height: "50vh",
                    
                }}
                
            >
                
                {malattie.map((malattia,index) => (
                    malattiaSelected === malattia.nome ? (
                        <li key={index}>
                            <div 
                                key={malattia.id}  
                                onClick={() => scegliMalattia(malattia)}
                                style={{
                                    display:"flex",
                                    flexDirection:"row",
                                    alignItems: "center",
                                    color: "#F7BE62"
                                }}
                            >
                                <div
                                    key={malattia.id}
                                    style={{
                                        width:"10px",
                                        height:"10px",
                                        backgroundColor: "#F7BE62",
                                        borderRadius: "5px",
                                        marginRight: "12px",
                                        minWidth: "10px",
                                        
                                    }}
                                />
                                {malattia.nome.toUpperCase()}
                            </div>
                        </li> 
                    ) : (
                        <li key={index}>
                            <div 
                                key={malattia.id}  
                                onClick={() => scegliMalattia(malattia)}
                                // style={{
                                //     color:`${malattiaSelected === malattia.nome ? "#F7BE62" : "white" }`,
                                //     fontFamily: `${malattiaSelected === malattia.nome ? "SemiBold" : "Regular" }`
                                // }}
                            >
                                {malattia.nome.toUpperCase()}
                            </div>
                        </li> 
                    )
                ))}
                
                {/* <li><div onClick={() => scegliMalattia("BOTULISMO")}>BOTULISMO</div></li>
                <li><div onClick={() => scegliMalattia("BRUCELLOSI")}>BRUCELLOSI</div></li>
                <li><div onClick={() => scegliMalattia("COLERA")}>COLERA</div></li>
                <li><div onClick={() => scegliMalattia("CRIPTOSPIRIDIOSI")}>CRIPTOSPIRIDIOSI</div></li>
                <li><div onClick={() => scegliMalattia("ANTRACE")}>ANTRACE</div></li>
                <li><div onClick={() => scegliMalattia("BOTULISMO")}>BOTULISMO</div></li>
                <li><div onClick={() => scegliMalattia("BRUCELLOSI")}>BRUCELLOSI</div></li>
                <li><div onClick={() => scegliMalattia("COLERA")}>COLERA</div></li>
                <li><div onClick={() => scegliMalattia("CRIPTOSPIRIDIOSI")}>CRIPTOSPIRIDIOSI</div></li>
                <li><div onClick={() => scegliMalattia("ANTRACE")}>ANTRACE</div></li>
                <li><div onClick={() => scegliMalattia("BOTULISMO")}>BOTULISMO</div></li>
                <li><div onClick={() => scegliMalattia("BRUCELLOSI")}>BRUCELLOSI</div></li>
                <li><div onClick={() => scegliMalattia("COLERA")}>COLERA</div></li>
                <li><div onClick={() => scegliMalattia("CRIPTOSPIRIDIOSI")}>CRIPTOSPIRIDIOSI</div></li> */}
            </div>

            <div
                style={{
                    padding: "4px 15px"
                }}
            >
                <ArrowDownward 
                    style={{
                        color:"white",
                        width: "12px",
                        height: "12px",
                    }}
                />
                <div 
                    style={{
                        padding: "0px 50px",
                        height: "1px",
                        backgroundColor: "#7585b5",
                        marginTop: "12px"
                    }}
                />
                <p
                    style={{
                        fontFamily:"Regular",
                        fontSize: "12px",
                        color: "#7585b5",
                        marginTop: "24px"
                    }}
                >
                    @ Lorem ipsum dolor sit amet, 2023
                </p>
            </div>
        </Ul>
    )
}

export default RightNav
import React from 'react';
import Chart from "react-apexcharts";

function TreemapChart(props){

    function scegliMalattia(malattia){

        let malattie = props.malattie;
        let malattiaSelected = {};
        for(let i = 0; i < malattie.length; i++){
            if(malattie[i].nome.toUpperCase() === malattia.x.toUpperCase()){
                malattiaSelected = malattie[i];
            }
        }
        
        props.handleMalattia(malattiaSelected);
    }

    const optionsChart = {
        options: {
            chart: {
                height: 312,
                type: "treemap",
                toolbar: {
                    show: false
                },
                events: {
                    click: function(event, chartContext, config){
                        console.log(config);
                        console.log(props.data);
                        scegliMalattia(props.data[config.dataPointIndex]);
                    },
                    dataPointMouseEnter: function(event) {
                        event.target.style.cursor = "pointer";
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                opacity: 1,
            },
            plotOptions: {
                
            },
            colors: ['#344472']
        },
        series: [
            {
                data: props.data
            }
        ]
        
        // series: [
        //     {
        //         data: [
        //             {
        //                 x: "New Delhi",
        //                 y: 109,
        //                 fillColor: ""
        //             },
        //             {
        //                 x: "Kolkata",
        //                 y: 149,
        //             },
        //             {
        //                 x: "Mumbai",
        //                 y: 184,
        //             },
        //             {
        //                 x: "Ahmedabad",
        //                 y: 55,
        //                 fillColor: "#F7BE62"
        //             },
        //             {
        //                 x: "Bangaluru",
        //                 y: 84,
        //             },
        //             {
        //                 x: "Pune",
        //                 y: 31,
        //             },
        //             {
        //                 x: "Chennai",
        //                 y: 700,
        //                 fillColor: ""
        //             }
        //         ],
        //     },
        // ]
        
    }

    return (
        <div>
            <Chart 
                options={optionsChart.options}
                series={optionsChart.series} 
                type='treemap'
                height={312}
            />
        </div>
    )
}

export default TreemapChart;
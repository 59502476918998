import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import SearchBar from '../utility/SearchBar';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { LogoTopBar2x } from '../..';
import Select from 'react-select';

const Nav = styled.nav`
        width: 100%;
        
        border-bottom: 2px solid #f1f1f1;
        padding: 0 20px;
        
        background-color: #ffffff;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0px;
        z-index: 10;
        .logo {
            padding: 15px 0;
        }
         
    `

const Navbar = (props) => {

    const [searchMobile, setSearchMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);


    function showSearchBar(){
        if(searchMobile === true){
            document.getElementById("mainNav").style.height = '60px'
            
        } else {
            document.getElementById("mainNav").style.height = '120px'
            
        }
        
        setSearchMobile(!searchMobile);
    }

    function scegliMalattia(malattia){
        props.handleMalattia(malattia);
    }

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth > 768){
                document.getElementById("mainNav").style.height = '60px';
                setSearchMobile(false);
            }
            setWidth(window.innerWidth);
            
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);

    return (
        <div>
            <Nav className='search-block-responsive' id="mainNav" style={{height:"60px", zIndex:"1000"}}>
                <div
                    className='row'
                >
                    <div className='col-12'>
                        <div
                            className='responsive-nav-margin'
                            style={{
                                //width: "50%",
                                display: "flex",
                                flexDirection:"row",
                                alignItems: "center",
                                marginLeft:"30px"
                            }}
                        >
                            <Burger 
                                malattie={props.malattie}
                                handleMalattia={scegliMalattia}
                                malattiaSelected={props.malattiaSelected}
                                handleOpen={props.handleOpen}
                            />
                            <img
                                className='responsive-logo-margin'
                                src={LogoTopBar2x}
                                alt=''
                                style={{
                                    height: "58px",
                                    marginLeft: "32px"
                                }}
                            />
                            <p
                                className='text-nav-responsive'
                                style={{
                                    fontFamily: "Regular",
                                    fontSize:"10px",
                                    color: "#7585b5",
                                    marginLeft: "4px",
                                    marginBottom: "0px"
                                }}
                            >
                                Servizio di riferimento
                                <span
                                    style={{
                                        fontFamily: "Bold",
                                        fontSize:"10px",
                                        color: "#7585b5",
                                        
                                    }}
                                >
                                    &nbsp;Regionale&nbsp;
                                </span>
                                di Epidemiologia per la sorveglianza, la prevenzione e il controllo delle
                                <span
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"10px",
                                        color: "red",
                                        
                                    }}
                                >
                                    &nbsp;Malattie infettive&nbsp;
                                </span>
                            </p>
                            <div
                                style={{
                                    width: "100%",
                                    alignItems:"center",
                                    display:"flex",
                                    justifyContent:"end"
                                }}
                            >
                                <div
                                    className='text-responsive'
                                    style={{
                                        marginRight: "32px",
                                    }}
                                >
                                    <SearchBar 
                                        handleMalattia={scegliMalattia}
                                        malattie={props.malattie}
                                    />
                                </div>
                                
                                <SearchIcon 
                                    className='search-icon-responsive'
                                    onClick={showSearchBar}
                                    style={{
                                        color: "#7585B5",
                                        width: "24px",
                                        height: "24px",
                                        display: "none",
                                        cursor: "pointer",
                                        marginRight: "12px"
                                    }}
                                />
                                {/* <InfoIcon 
                                    className='info-icon-responsive'
                                    style={{
                                        color: "#7585B5",
                                        marginRight: "60px",
                                        marginLeft: "24px",
                                        width: "32px",
                                        height: "32px"
                                    }}
                                /> */}
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                { searchMobile === true ? (
                    <div
                        className='row'
                    >
                        <div className='col-12'
                            style={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }}
                        >
                            <SearchBar 
                                handleMalattia={scegliMalattia}
                                malattie={props.malattie}
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </Nav>
            
            
        </div>
    )
}

export default Navbar
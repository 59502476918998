const Legend = (props) => {
    return (
        <div className={props.isRegion === true ?  "row legend-responsive" : "row"}
            style={{
                display: props.isRegion === true ? "none" : "block"
            }}
        >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"center",
                    textAlign:"center",
                    padding:"8px 0px",
                    width:"100%",
                    marginTop:"20px",
                
                }}
            >
                <div 
                    style={{
                        backgroundColor:"#344472",
                        width:"12px",
                        height:"12px",
                        borderRadius: "6px"
                    }}
                />
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"10px",
                        color:"#7585b5",
                        marginLeft:"1px"
                    }}
                >
                    0-14 anni
                </label>

                <div 
                    style={{
                        backgroundColor:"#F7BE62",
                        width:"12px",
                        height:"12px",
                        marginLeft: "8px",
                        borderRadius: "6px"
                    }}
                />
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"10px",
                        color:"#7585b5",
                        marginLeft:"1px"
                    }}
                >
                    15-24 anni
                </label>

                <div 
                    style={{
                        backgroundColor:"#D5942C",
                        width:"12px",
                        height:"12px",
                        marginLeft: "8px",
                        borderRadius: "6px"
                    }}
                />
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"10px",
                        color:"#7585b5",
                        marginLeft:"1px"
                    }}
                >
                    25-44 anni
                </label>

                <div 
                    style={{
                        backgroundColor:"#DDE4F9",
                        width:"12px",
                        height:"12px",
                        marginLeft: "8px",
                        borderRadius: "6px"
                    }}
                />
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"10px",
                        color:"#7585b5",
                        marginLeft:"1px"
                    }}
                >
                    45-64 anni
                </label>

                <div 
                    style={{
                        backgroundColor:"#7585B5",
                        width:"12px",
                        height:"12px",
                        marginLeft: "8px",
                        borderRadius: "6px"
                    }}
                />
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"10px",
                        color:"#7585B5",
                        marginLeft:"1px"
                    }}
                >
                    over 65 anni
                </label>
            </div>
        </div>
    );
}

export default Legend;
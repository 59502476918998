import React, {useState} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';

const AgeFilter = (props) => {

const options = [
    {
        value:-1,
        label: "Totale"
    },
    {
        value:null,
        label:"Tutti i range età"
    },
    {
        value:0,
        label:"0-14 anni"
    },
    {
        value:1,
        label:"15-24 anni"
    },
    {
        value:2,
        label:"25-44 anni"
    },
    {
        value:3,
        label:"45-64 anni"
    },
    {
        value:4,
        label:"over 65"
    }
]


const handleChange = event => {
    if(event && event.label){
        props.handleAgeFilter({
            value: event.value,
            label: event.label
        });
    } else {
        props.handleAgeFilter({
            value: null,
            label: "Totale"
        });
    }
    
};


return (
    <div 
        className='justify-center-responsive'
        style={{
            display: "flex",
            alignItems:"center",
            justifyContent:"right",
            position: "relative",
            width: "100%",
            zIndex:"100"
        }}
    >
        {/* <label
            className='info-legend-responsive filter-resp-1350'
            style={{
                fontFamily:"Medium",
                fontSize:"12px",
                color:"#7585B5",
                marginRight:"20px"
            }}
        >
            Filtra per:
        </label> */}
        <Select 
            options={options} 
            placeholder="Totale"
            onChange={handleChange}
            isClearable={true}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    width:"200px",
                    height: "44px",
                    padding: "0px 12px",
                    borderRadius: "24px",
                    border: "2px solid #dde4f9",
                    backgroundColor: "#f6f8fe",
                    
                    fontFamily: "Regular",
                    fontSize: "14px",
                })
            }}
            
        />

    </div>


)};

export default AgeFilter;
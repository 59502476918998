import './App.css';
import './font/Poppins-Light.ttf';
import './font/Poppins-Regular.ttf';
import './font/Poppins-SemiBold.ttf';
import './font/Poppins-Bold.ttf';
import { HomeView } from './view/HomeView';
import { HomeView2 } from './view/HomeView2';
import Navbar from './components/nav/NavBar';
import React, {useState, useEffect} from 'react';
import LoaderCustom from './components/utility/LoaderCustom';
import { getMalattie } from './api/api';
import useDebounce from './hooks/useDebounce';
import axios from 'axios';


function App(){

    const [malattiaSelected, setMalattiaSelected] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [malattieList, setMalattieList] = useState([]);
    const [malattiaId, setMalattiaId] = useState("");
    const [stat, setStat] = useState({});
    const [malattiaInfo, setMalattiaInfo] = useState({});
    const [visibilityAsl, setVisibilityAsl] = useState(false);
    const [visibilityProvincia, setVisibilityProvincia] = useState(false);

    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    const [version, setVersion] = useState("1");

    const [open, setOpen] = useState(!isMobile());

    function handleOpen(open){
        setOpen(open);
    }

    function setVersionNumber(v){
        setVersion(v);
    }

    function isMobile(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // true for mobile device
            console.log("IS MOBILE");
            return true;
        } else {
            // false for not mobile device
            console.log("IS NOT MOBILE");
            return false;
        }
    }

    function setDiseaseView(disease){
        window.history.replaceState(null, "", encodeURIComponent(disease.nome).toLowerCase());
        setCurrentPath(encodeURIComponent(window.location.pathname));
        setMalattiaSelected(disease.nome);
        setVisibilityAsl(disease.section_asl);
        setVisibilityProvincia(disease.section_province);
        setMalattiaId(disease._id);
        setMalattiaInfo(disease.info);
    }

    useEffect(() => {

       executeGetMalattie();

    }, [])

    const executeGetMalattie = () => {
        setIsLoading(true);
        axios.get('https://seremi.etihub.it/api/malattie').then((response) => {

            if(response.data && response.data.malattie){
                setMalattieList(response.data.malattie);
                if(currentPath && currentPath !== "" && currentPath !== "/"){
                    var found = false;
                    let malattia = currentPath.slice(1);
                    //malattia.replace(/%20/g, ' ')
                    response.data.malattie.forEach(elem => {
                        if(elem.nome.toUpperCase() === decodeURIComponent(malattia).toUpperCase()){
                            found = true;
                            setMalattiaSelected(elem.nome);
                            setMalattiaInfo(elem.info)
                            setVisibilityAsl(elem.section_asl);
                            setVisibilityProvincia(elem.section_province);
                            executeGetStatById(elem._id);
                            
                        }
                    });
                    if(!found){
                        setCurrentPath("");
                        setMalattiaSelected(response.data.malattie[0].nome);
                        setMalattiaInfo(response.data.malattie[0].info);
                        setVisibilityAsl(response.data.malattie[0].section_asl);
                        setVisibilityProvincia(response.data.malattie[0].section_province);
                        executeGetStatById(response.data.malattie[0]._id);
                        
                    }
                } else {
                    setMalattiaSelected(response.data.malattie[0].nome);
                    setMalattiaInfo(response.data.malattie[0].info);
                    setVisibilityAsl(response.data.malattie[0].section_asl);
                    setVisibilityProvincia(response.data.malattie[0].section_province);
                    executeGetStatById(response.data.malattie[0]._id);
                    
                }
            }

        });
    }

    useEffect(() => {
        if(malattiaId !== ""){
            executeGetStatById(malattiaId);
        }

     }, [malattiaId])

    const executeGetStatById = (idMalattia) => {
        setIsLoading(true);
        axios.get('https://seremi.etihub.it/api/stats/' + idMalattia).then((response) => {

            if(response.data ){
                setStat(response.data);
                if(isMobile()){
                    setOpen(false);
                } else {
                    setOpen(true);
                }
                setIsLoading(false);
            }

        });
    }

    // return (
    //     <div
    //         style={{
    //             position: "absolute",
    //             top: "50%",
    //             left: "50%",
    //             marginTop: "-50px",
    //             marginLeft: "-50px",
    //             width: "100px",
    //             height: "100px"
    //         }}
    //     >
    //         <LoaderCustom />

    //     </div>
    // )
    return (
        <div>
            {isLoading ? (
                <div>
                    <div
                        style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            marginTop: "-50px",
                            marginLeft: "-50px",
                            width: "100%",
                            height: "100%",
                            zIndex: "1000"
                        }}
                    >
                        <LoaderCustom
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%"
                            }}
                        />
                    </div>
                    {/* <div
                        style={{
                            opacity: "0.3"
                        }}
                    >
                        <Navbar malattiaSelected={malattiaSelected} malattie={malattieList} handleMalattia={setDiseaseView} />
                        <HomeView  malattie={malattieList} malattiaSelected={malattiaSelected} />
                    </div> */}
                </div>
            ) : (
                <div

                >
                    <Navbar malattiaSelected={malattiaSelected} malattie={malattieList} handleMalattia={setDiseaseView} handleOpen={handleOpen}/>
                    {/* {version === "1" ? (
                        <HomeView malattie={malattieList} malattiaSelected={malattiaSelected} stat={stat} info={malattiaInfo} visibilityAsl={visibilityAsl} handleMalattia={setDiseaseView} setVersionNumber={setVersionNumber}/>
                    ) : ( */}
                    <HomeView2 isOpen={open} malattie={malattieList} malattiaSelected={malattiaSelected} stat={stat} info={malattiaInfo} visibilityProvincia={visibilityProvincia} visibilityAsl={visibilityAsl} handleMalattia={setDiseaseView} setVersionNumber={setVersionNumber}/>
                    {/* )} */}
                    
                </div>
            )}

        </div>
    );

}

export default App;

import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  z-index: 30;
  display: none;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  
  div {
    width: 2rem;
    height: 0.20rem;
    background-color: ${({ open }) => open ? '#344472' : '#344472'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;



const Burger = (props) => {
  const [open, setOpen] = useState(!isMobile());

  function isMobile(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        
        return true;
    } else {
        // false for not mobile device
        
        return false;
    }
  }

  function closeMenu(){
    setOpen(false);
    props.handleOpen(false)
  }

  function openMenu(){
    setOpen(!open);
    props.handleOpen(!open)
  }
  
  return (
    <>
        
        <StyledBurger open={open} onClick={openMenu}>
            <div />
            <div />
            <div />
        </StyledBurger>
        <RightNav malattie={props.malattie} open={open} handleMalattia={props.handleMalattia} malattiaSelected={props.malattiaSelected} closeMenu={closeMenu}/>
    </>
  )
}

export default Burger
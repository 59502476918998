import React from 'react';
import Chart from "react-apexcharts";

function StackedColumnsChart({id, stat, data, heightFixed}){
    let colors = data.colors;
    let series = data.series;
    
    const optionsChart = {
        options: {
            chart: {
                id: id,
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                }
            },
            dataLabels: {
                enabled: false,
                
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10, 
                    borderRadiusApplication: "end",
                    borderRadiusWhenStacked: "last"                   
                }
            },
            fill: {
                opacity: 1,
                colors: colors
            },
            legend:{
                show: true,
                position: "bottom",
                markers:{radius: 10},
                offsetX: 0,
                offsetY: 8,
                fontFamily: "Regular",
                fontSize: '12px',
                labels:{
                    colors:["#7585B5"]
                }
            },
            xaxis: {
                categories: stat.categories,
                labels:{
                    style: {
                        colors:"#7585b5",
                        fontFamily:"Regular",
                        fontSize:"12px"
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'NUMERO CASI',
                    style: {
                        fontFamily:"SemiBold",
                        fontSize: "12px",
                        color:"#7585b5"
                    },
                },
                labels:{
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                    
                    style: {
                        colors:["#7585b5"],
                        fontFamily:"Regular",
                        fontSize:"12px"
                    },
                },
                tickAmount: 1
            },
            
        },
        series: series,
        
    }

    

    return (
        <div>
            <Chart 
                options={optionsChart.options}
                series={optionsChart.series} 
                type='bar'
                height= "310px"
                
                
            />
        </div>
    )
}

export default StackedColumnsChart;
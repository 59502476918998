import React from 'react';
import Chart from "react-apexcharts";

function BarStacked100Chart(){
    const optionsChart = {
        series: [{
                name: '0-6 anni',
                data: [44]
            }, {
                name: '6-12 anni',
                data: [53]
            }, {
                name: '12-18 anni',
                data: [12]
            }, {
                name: '18-99 anni',
                data: [9]
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 200,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    borderRadius: 10
                },
                fill: {
                    opacity: 1
                    
                },
                legend: {
                    show:false
                }
            },
            xaxis: {
                categories: [2023],
            },
            
        },
        
        
    }

    return (
        <div>
            <Chart 
                options={optionsChart.options}
                series={optionsChart.series} 
                type='bar'
                height={120}
            />
        </div>
    )
}

export default BarStacked100Chart;
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip as ReactTooltip } from "react-tooltip";

function TwoRowStat2({title, totale, totPreviousYear, id, incidenza, incidenzaStringa, codice, isClicked, setProvincia, isProvincia, setAsl, isAsl}){

    function renderCustomTooltip(){
        return (
            <div>
               
                <p className="tooltip-grey-font no-margin">
                    Incidenza:
                    <span className="tooltip-yellow-font">&nbsp;{incidenzaStringa && incidenzaStringa !== "" ? incidenzaStringa : "0"}</span>
                </p>
            
            </div>
        )
    }

    function handleData(){
        if(isProvincia){
            setProvincia({
                provincia:title,
                codice:codice
            })
        } else {
            setAsl({
                asl: title,
                codice: codice
            })
        }
    }

    return (
        <div
            className="stat-two-row-responsive"
            onClick={handleData}
            style={{
                padding:"14px",
                backgroundColor: `${isClicked ? "#F7BE6280" : "#dde4f9"}` ,
                borderRadius: "28px",
                cursor:"pointer"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <label
                    style={{
                        fontFamily:"SemiBold",
                        fontSize:"14px",
                        color: "#344472" ,
                    }}
                >
                    {title}
                </label>
                
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    {/* <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"12px",
                            color:"#7585b5",
                            marginLeft:"16px"
                        }}
                    >
                        {props.incidenza} 
                    </label> */}
                    {/* <InfoIcon 
                        id={props.id}
                        
                        style={{
                            color:"#d5942c",
                            width:"18px",
                            height:"18px",
                            marginLeft:"8px"
                        }}
                    /> */}
                    <ReactTooltip
                        anchorId={id}
                        place="top"
                        border="2px solid #DDE4F9"
                        style={{
                            backgroundColor:"#F6F8FE",
                            borderRadius: "16px",
                            fontFamily: "Medium",
                            color: "#261D56",
                            //opacity: statRegione.counters.last_years[statRegione.counters.last_years.length-1].provincia["103"].opacity
                        }}
                        render={() => renderCustomTooltip()}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "0px"
                }}
            >
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"12px",
                        color: "#7585B5",
                        marginRight: "4px"
                    }}
                >
                    Num. casi:
                </label>
                <label
                    style={{
                        fontFamily:"SemiBold",
                        fontSize:"14px",
                        color:"#344472",
                        
                    }}
                >
                    {totale ? totale : "0"}
                </label>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <label
                    style={{
                        fontFamily:"Regular",
                        fontSize:"12px",
                        color: "#7585B5",
                        marginRight: "4px"
                    }}
                >
                    Incidenza:
                </label>
                <div
                    style={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <InfoIcon 
                        id={id}
                        
                        style={{
                            color:"#d5942c",
                            width:"14px",
                            height:"14px",
                            marginRight:"4px"
                        }}
                    />
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"14px",
                            color:"#344472"
                            
                        }}
                    >
                        {incidenza ? incidenza : 0}
                    </label>
                </div>
            </div>
        </div>
    )
}

export default TwoRowStat2;
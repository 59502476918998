import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

import LogoFooter from "./images/seremi_logo_footer.png";
import LogoFooter2x from "./images/seremi_logo_footer@2x.png";

import LogoTopBar from "./images/seremi_logo_topbar.png";
import LogoTopBar2x from "./images/seremi_logo_topbar@2x.png";

import BackImage from "./images/MI-modificata.png";

import IllustrazioneHome from "./images/Illustrazione-DEF.png";
import IllustrazioneBox from "./images/Illustrazione.png";

import AlessandriaImg from "./images/regione/chart_regione_alessandria.png";
import AstiImg from "./images/regione/chart_regione_asti.png";
import BiellaImg from "./images/regione/chart_regione_biella.png";
import CuneoImg from "./images/regione/chart_regione_cuneo.png";
import NovaraImg from "./images/regione/chart_regione_novara.png";
import TorinoImg from "./images/regione/chart_regione_torino.png";
import VerbanoImg from "./images/regione/chart_regione_verbano-cusio-ossola.png";
import VercelliImg from "./images/regione/chart_regione_vercelli.png";

export { LogoFooter };
export { LogoFooter2x };

export { LogoTopBar };
export { LogoTopBar2x };

export { BackImage };

export { IllustrazioneHome };
export {IllustrazioneBox};

export { AlessandriaImg };
export { AstiImg };
export { BiellaImg };
export { CuneoImg };
export { NovaraImg };
export { TorinoImg };
export { VerbanoImg };
export { VercelliImg };

const el = document.getElementById('root');

const root = ReactDOM.createRoot(el);

root.render(<App/>);
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InfoIcon from '@material-ui/icons/InfoOutlined';

function OneRowStat({title, totale, totPreviousYear, id, incidenza, incidenzaStringa}){

    function renderCustomTooltip(){
        return (
            <div>
               
                <p className="tooltip-grey-font no-margin">
                    Incidenza:
                    <span className="tooltip-yellow-font">&nbsp;{incidenzaStringa && incidenzaStringa !== "" ? incidenzaStringa : "0"}</span>
                </p>
            
            </div>
        )
    }

    function setPercentuale(){
        let old_cases = totPreviousYear;
        let current_cases = totale;

        if(old_cases === 0 || current_cases === 0){
            return (
                <div
                    style={{
                        width:"90%",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"16px",
                            color:"#344472",
                            marginLeft:"32px"
                        }}
                    >
                        {title}
                    </label>
                </div>
            )
        }

        let percentuale = parseInt((parseInt(current_cases) * 100 / parseInt(old_cases)));
        if(percentuale > 100){
            return (
                <div
                    style={{
                        width:"90%",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"16px",
                            color:"#344472",
                            marginLeft:"32px"
                        }}
                    >
                        {title}
                    </label>
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"12px",
                            color:"#7585b5",
                            marginLeft:"16px"
                        }}
                    >
                        {percentuale - 100} %
                    </label>
                    <ArrowDropUp 
                        style={{
                            color:"#d5942c",
                            width:"24px",
                            height:"24px"
                        }}
                    />
                </div>
            )
        } else {
            return (
                <div
                    style={{
                        width:"90%",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"16px",
                            color:"#344472",
                            marginLeft:"32px"
                        }}
                    >
                        {title}
                    </label>
                    <label
                        style={{
                            fontFamily:"SemiBold",
                            fontSize:"12px",
                            color:"#7585b5",
                            marginLeft:"16px"
                        }}
                    >
                        {100 - percentuale } %
                    </label>
                    <ArrowDropDown 
                        style={{
                            color:"#d5942c",
                            width:"24px",
                            height:"24px"
                        }}
                    />
                </div>
            )
        }
    }

    return (
        <div className="row">
            <div 
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 stat-one-row-responsive"
                style={{
                    marginTop:"12px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <div 
                    className="box-stat-height-responsive"
                    style={{
                        backgroundColor: "#7585b5",
                        width: "4px",
                        height: "84px",
                        marginRight:"4px"
                    }}
                >
            

                </div>

                <div 
                    className="box-stat-height-responsive"
                    style={{
                        backgroundColor: "#dde4f9",
                        height: "84px",
                        width: "100%",
                        borderBottomRightRadius: "28px",
                        borderTopRightRadius: "28px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    
                    <div
                        style={{
                            //width:"70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <label
                            style={{
                                fontFamily:"SemiBold",
                                fontSize:"16px",
                                color:"#344472",
                                marginLeft:"16px"
                            }}
                        >
                            {title}
                        </label>
                        {/* <label
                            style={{
                                fontFamily:"SemiBold",
                                fontSize:"12px",
                                color:"#7585b5",
                                marginLeft:"16px"
                            }}
                        >
                            {incidenza}
                        </label> */}
                        {/* <InfoIcon 
                            id={id}
                            
                            style={{
                                color:"#d5942c",
                                width:"18px",
                                height:"18px",
                                marginLeft:"8px"
                            }}
                        /> */}
                        <ReactTooltip
                            anchorId={id}
                            place="top"
                            border="2px solid #DDE4F9"
                            style={{
                                backgroundColor:"#F6F8FE",
                                borderRadius: "16px",
                                fontFamily: "Medium",
                                color: "#261D56",
                                //opacity: statRegione.counters.last_years[statRegione.counters.last_years.length-1].provincia["103"].opacity
                            }}
                            render={() => renderCustomTooltip()}
                        />
                    </div>
                    <div
                        style={{
                            marginRight:"24px"
                        }}
                    >
                        <div 
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "right",
                                alignItems: "center",
                                marginTop: "0px"
                            }}
                        >
                            <label
                                style={{
                                    fontFamily:"Regular",
                                    fontSize:"14px",
                                    color: "#7585B5",
                                    marginRight: "4px"
                                }}
                            >
                                Num. casi:
                            </label>
                            <label
                                style={{
                                    fontFamily:"SemiBold",
                                    fontSize:"20px",
                                    color:"#344472",
                                    
                                }}
                            >
                                {totale ? totale : "0"}
                            </label>
                        </div>
                        <div 
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "right",
                                alignItems: "center",
                                marginTop: "0px"
                            }}
                        >
                            <label
                                style={{
                                    fontFamily:"Regular",
                                    fontSize:"14px",
                                    color: "#7585B5",
                                    marginRight: "4px"
                                }}
                            >
                                Incidenza:
                            </label>
                            <InfoIcon 
                                id={id}
                                
                                style={{
                                    color:"#d5942c",
                                    width:"18px",
                                    height:"18px",
                                    marginRight:"4px"
                                }}
                            />
                            <label
                                style={{
                                    fontFamily:"SemiBold",
                                    fontSize:"20px",
                                    color:"#344472",
                                    
                                }}
                            >
                                {incidenza ? incidenza : 0}
                            </label>
                        </div>
                    </div>
                </div>
            

            </div>
        </div>
    )
}

export default OneRowStat;
import { ThreeCircles } from 'react-loader-spinner';

const LoaderCustom = (props) => {
    return (
        <ThreeCircles
            height="100"
            width="100"
            color=""
            
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor="#344472"
            innerCircleColor="#D5942C"
            middleCircleColor="#7585B5"
            wrapperStyle={{
                position:"absolute",
                top:"50%",
                left:"50%"
            }}
            
        />
    )
}

export default LoaderCustom;